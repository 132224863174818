* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: block;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  align-content: center;
}

.Header {
  position: sticky;
  top: 0px;
  z-index: 9;
}
