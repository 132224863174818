.Home {
  margin: 3em auto;
  width: 50%;
}

.Description img {
  display: block;
  height: 14em;
  width: 14em;
  float: left;
  margin: 0 2em 2em 0;
}

.Description p {
  font-size: 0.95em;
  color: black;
  line-height: 150%;
  margin: 1em 0;
}

.Contacts {
  margin: 4em 0;
  clear: both;
  text-align: center;
}

.Contacts p {
  margin: 0.5em auto;
}

.Contacts a {
  color: #565353;
}

@media (max-width: 1025px) {
  .Home {
    width: 60%;
  }
}

@media (max-width: 961px) {
  .Home {
    width: 70%;
  }
}

@media (max-width: 641px) {
  .Home {
    width: 80%;
  }
}

@media (max-width: 481px) {
  .Home {
    font-size: 1.1em;
    width: 80%;
    margin: 2em auto;
  }
  .Description img {
    width: 80%;
    height: auto;
    margin: 0 auto;
    float: none;
  }
  .Description p {
    margin: 2em 0;
  }
  .Contacts {
    margin: 3em 0;
  }
}

@media (max-width: 320px) {
  .Home {
    font-size: 1.1em;
    width: 80%;
    margin: 2em auto;
  }
  .Description img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    float: none;
  }
  .Description p {
    margin: 2em 0;
  }
  .Contacts {
    margin: 3em 0;
  }
}
