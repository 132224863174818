.SiteLogo {
  height: 11vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 246, 247);
}

.SiteLogo img {
  height: 4em;
}

@media (max-width: 1025px) {
  .SiteLogo {
    font-size: 0.85em;
  }
}

@media (max-width: 961px) {
  .SiteLogo {
    font-size: 0.8em;
  }
}

@media (max-width: 641px) {
  .SiteLogo {
    font-size: 0.75em;
  }
}

@media (max-width: 481px) {
  .SiteLogo {
    font-size: 0.65em;
  }
  .SiteLogo img {
    height: 4em;
  }
}

@media (max-width: 320px) {
  .SiteLogo {
    font-size: 0.55em;
  }
  .SiteLogo img {
    height: 4em;
  }
}
